import React from 'react';
import { graphql } from 'gatsby';
import { Parallax } from 'react-spring/renderprops-addons';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import ComponentParser from '../components/component.parser';
import Content from '../components/component.content';
import CookieNotice from "../components/component.cookie-notice";

const PageTemplate = ({ data }) => {
    const { title, content, blocks, seo, featuredImage } = data.wordpress.page;
    
    return (
        <>
            <Parallax scrolling={true}>
                <Layout>
                    <SEO title={title} seo={seo} image={featuredImage} />
                    {blocks &&
                        <ComponentParser blocks={blocks} parallax />
                    }
                    {content &&
                        <Content content={content} />
                    }
                </Layout>
            </Parallax>
            <CookieNotice />
        </>
       
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...PageBlocks
            }
        }
    }
`;

export default PageTemplate;
